import styled from "styled-components";

export const StyledCard = styled.div`
    background-color: white;
    padding: 4rem;
    margin: 2rem 0;
    /* max-width: 637px; */
    width: 100%;
    border-radius: 6px;
    h4 {
        font-weight: 700;
        margin-bottom: 2.5rem;
        font-size: 25px;
    }
    .card-inner {
        overflow: auto;
        .title-name {
            padding-bottom: 15px;
            color: black;
        }
        .title-name-global-setting {
            padding: 15px 0px 10px 0px;
            color: black;
        }
        .div-form-item {
            padding: 10px 0px 10px 0px;
            p {
                color: black;
                font-weight: 600;
            }
        }
    }
    .card-feature {
        background: #f0f2f5;
        padding: 0.5rem 1.5rem;
        border-radius: 8px;
    }
    .field-item {
        display: flex;
        .field-name {
            width: 100%;
            border: 1px solid #d6d6d6;
            margin: 10px 0px;
            padding: 10px;
            border-radius: 4px;
            background-color: #f5f5f5;
        }
        p {
            margin-top: 20px;
            margin-left: 10px;
            cursor: pointer;
        }

    }
`;
