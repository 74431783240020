import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Input, Button, Radio, Divider, Switch, Row, Col, Drawer, Select } from "antd";
import { IMerchantAdmin, IMerchantDetail } from "@interfaces";
import { IClose, IconArrowRight, IconDelete, IconDrag, StyledCard } from "@components";
import React, { useState, useEffect } from "react";
import { merchantAPI } from "@api";
import { useDispatch } from "react-redux";
import { openToast, setLoading } from "@redux";
const { TextArea } = Input;
const { Option } = Select;


interface IItem {
    title: string;
    content: string | number | any;
    email?: string;
}
interface IData {
    data: IMerchantDetail | undefined;
    merchantAdmin: IMerchantAdmin[] | undefined;
}
const CardItem = (props: IItem) => (
    <ContentInfo>
        <p className={"cate-info"}>{props.title}</p>
        <p className={"content-info"}>{props.content}</p>
        {props?.email && <p className={"content-info"}>{props.email}</p>}
    </ContentInfo>
);


const initialFormGlobalSetting = {
    isTier: true,
    isSellerTier: false,
    uploadEvidenceDisplay: "",
    uploadUnitDisplay: "",
    isFullName: true,
    membershipTiersId: ""
};



interface IBookingField {
    booking_topic_note_name: string;
}

export const ComponentCardMerchant = (props: IData) => {
    //page hook
    const { t } = useTranslation();
    //page prop
    const { data, merchantAdmin } = props;
    const [note, setNote] = useState("");
    const [globalSetting, setGlobalSetting] = useState(1);
    const [swFeatureAdvanced, setSWFeatureAdvanced] = useState<any>();
    const [swCollectPoint, setSWCollectPoint] = useState<any>();
    const [formGlobalSetting, setFormGlobalSetting] = useState<any>(initialFormGlobalSetting);
    const [formFormUploadReceipt, setFormUploadReceipt] = useState<any>();
    const dispatch = useDispatch();
    const [showBookingField, setShowBookingField] = useState<boolean>(false);
    const [newField, setNewField] = useState<string>("");
    const [BookingFieldList, setBookingFieldList] = useState<IBookingField[]>([]);
    const [selectTier, setSelectTier] = useState<any[]>([]);
    const [tierBy, setTierBy] = useState<number>(1);
    const [formCrossMerchantReceipt, setFormCrossMerchantReceipt] = useState<any>();
    const [arrayCrosssChannel, setArrayCrosssChannel] = useState<any>([]);
    const [dataChanel, setDataChanel] = useState<any>([]);
    const [dataChanelAnotherMerchant, setDataChanelAnotherMerchant] = useState<any>([]);
    useEffect(() => {
        if (data) {
            fetchGetUploadReciept();
            fetchGetCrossMerchantReceipt();
            if (data?.superAdminNote) {
                setNote(data.superAdminNote);
            }
            if (data?.superAdminTier) {
                setFormGlobalSetting({
                    isTier: data?.superAdminTier?.isTier,
                    isSellerTier: data?.superAdminTier?.isSellerTier,
                    uploadEvidenceDisplay: data?.superAdminTier?.uploadEvidenceDisplay,
                    uploadUnitDisplay: data?.superAdminTier?.uploadUnitDisplay,
                    isFullName: data?.superAdminTier?.isFullName,
                    isRound: data?.superAdminTier?.isComponentRound,
                    isDisplayTier: data?.superAdminTier?.isTierDisplay,
                });
                if (data?.superAdminTier?.isPointBalance && !data?.superAdminTier?.isPointEarn) {
                    setGlobalSetting(1);
                } else {
                    setGlobalSetting(2);
                }
                if (data?.superAdminTier?.isTierByPoint && !data?.superAdminTier?.isTierBySale) {
                    setTierBy(1);
                } else {
                    setTierBy(2);
                }
            }
            if (data?.superAdminAdvanceFeature) {
                setSWFeatureAdvanced(data?.superAdminAdvanceFeature);
            }
            if (data?.superAdminCollectPointFeature) {
                setSWCollectPoint(data?.superAdminCollectPointFeature);
            }
            if (data?._id) {
                fetchFieldData();
                getChanel(data?._id);
            }
            if (data?.membershipTiers){
                setSelectTier(data?.membershipTiers);
            }
        }
    }, [data]);

    useEffect(() => {
        if (formCrossMerchantReceipt?.anotherMerchant) {
            getChanelAnotherMerchantId(formCrossMerchantReceipt?.anotherMerchant);
        } else {
            setDataChanelAnotherMerchant([]);
            const arrValue: any[] = [];
            const list = [...arrayCrosssChannel];
            for (let index = 0; index < list.length; index++) {
                const element = list[index];

                arrValue.push({
                    channelId: element?.channelId,
                    crossChannelId: "",
                });
            }
            setArrayCrosssChannel(arrValue);
        }
    }, [formCrossMerchantReceipt]);
   

    const fetchGetUploadReciept = async () => {
        let res = await merchantAPI.geConfigUploadReciept(data?._id);
        setFormUploadReceipt(res.data.result.uploadReceiptConfig);
    };

    const fetchGetCrossMerchantReceipt = async () => {
        let res = await merchantAPI.getCrossMerchantByID(data?._id);
        setFormCrossMerchantReceipt(res.data?.result?.crossMerchantSetting);
        setArrayCrosssChannel(res.data?.result?.crossMerchantSetting?.crossChannel || []);
    };

    const getChanel = async (id: any) => {
        const response = await merchantAPI.getChanel(id); 
        if (response.status === 200) {
            await setDataChanel(response.data);
        }
    };

    const getChanelAnotherMerchantId = async (id: any) => {
        const response = await merchantAPI.getChanelAnotherMerchant(id); 
        if (response.status === 200) {
            await setDataChanelAnotherMerchant(response.data);
        }
    };

    const fetchFieldData = async () => {
        await null;
        let res = await merchantAPI.getBookingFieldList({
            organization_id: data?._id,
        });
        let arr: IBookingField[] = [];
        res.data.result?.forEach((fItem: IBookingField) => {
            arr.push({
                booking_topic_note_name: fItem?.booking_topic_note_name,
            });
        });
        setBookingFieldList([...arr]);
    };


    const clientLink = data
        ? `${process.env.REACT_APP_CLIENT_URL}/client-link/${data?._id}`
        : "---";

    const handleChange = (e: React.ChangeEvent<any>) => {
        setNote(e.target.value);
    };



    const saveField = () => {
        if (newField) {
            let arr = [...BookingFieldList];
            arr.push({
                booking_topic_note_name: newField,
            });
            setBookingFieldList([...arr]);
        }

        setNewField("");
        setShowBookingField(false);
    };

    const cancelField = () => {
        setNewField("");
        setShowBookingField(false);

    };

    const deleteField = (fItem: IBookingField) => {
        let arr = BookingFieldList.filter(item => item.booking_topic_note_name !== fItem.booking_topic_note_name);
        setBookingFieldList([...arr]);
    };

    const handleSent = async () => {
        dispatch(setLoading(true));
        const response = await merchantAPI.superAdminPostNote({
            merchantId: data?._id || "",
            note: note,
        });
        if (response.data) {
            dispatch(
                openToast({
                    message: "Success",
                    type: "success",
                    autoHideDuration: 2000,
                })
            );
            dispatch(setLoading(false));
        }
    };

    const addFieldsList = () => {
        const arr: any = [...arrayCrosssChannel];
        arr.push({
            channelId: "",
            crossChannelId: "",
        });
        setArrayCrosssChannel(arr);
    };

    const changeFieldListForm = (val: any, indexFields: number, key: string) => {
        const updatedFieldListForm = [...arrayCrosssChannel];
        if (key === "channelId") {
            updatedFieldListForm[indexFields] = {
                ...updatedFieldListForm[indexFields],
                channelId: val,
            };
            setArrayCrosssChannel(updatedFieldListForm);
        } else {
            updatedFieldListForm[indexFields] = {
                ...updatedFieldListForm[indexFields],
                crossChannelId: val,
            };
            setArrayCrosssChannel(updatedFieldListForm);
        }
    };

    const handleDeleteFields = (index: number) => {
        const gList = [...arrayCrosssChannel];
        gList.splice(index, 1);
        setArrayCrosssChannel(gList);
    };

    const saveBooking = async () => {
        // save booking
        await null;
        let payload = {
            organization_id: data?._id,
            booking_topic_note_list: BookingFieldList,
        };
        let res = await merchantAPI.updateBookingFieldList(payload);
        if (res.data.error === "0") {
            dispatch(
                openToast({
                    message: "Update Success",
                    type: "success",
                    autoHideDuration: 2000,
                })
            );
        }
    };

    const handleSaveMerchantGlobalSetting = async () => {
        dispatch(setLoading(true));

        const res = await merchantAPI.superAdminFeature({
            ...swFeatureAdvanced,
            ...swCollectPoint,
            merchantId: data?._id || "",
            isMemberShipTier: swFeatureAdvanced?.isMemberShipTier
        });
        if (res.data.status !== 400) {
            const response = await merchantAPI.superAdminTierRule({
                merchantId: data?._id || "",
                isPointBalance: globalSetting === 1 ? true : false,
                isPointEarn: globalSetting === 2 ? true : false,
                isTier: formGlobalSetting?.isTier,
                isSellerTier: formGlobalSetting?.isSellerTier,
                uploadEvidenceDisplay: formGlobalSetting?.uploadEvidenceDisplay,
                uploadUnitDisplay: formGlobalSetting?.uploadUnitDisplay,
                isFullName: formGlobalSetting?.isFullName,
                isComponentRound: formGlobalSetting?.isRound,
                isComponentSharp: !formGlobalSetting?.isRound,
                isTierDisplay: formGlobalSetting?.isDisplayTier,
                isTierByPoint: tierBy === 1 ? true : false,
                isTierBySale: tierBy === 2 ? true : false,
            });
            if (response.data) {
                dispatch(
                    openToast({
                        message: "Success",
                        type: "success",
                        autoHideDuration: 2000,
                    })
                );
                dispatch(setLoading(false));
            }
        } else {
            dispatch(
                openToast({
                    message: res.data.message,
                    type: "error",
                    autoHideDuration: 2000,
                })
            );
            dispatch(setLoading(false));
        }

    };


    const handleSaveAdvancedFeatureAndCollectPoint = async () => {
        dispatch(setLoading(true));
        const response = await merchantAPI.superAdminFeature({
            merchantId: data?._id || "",
            isCard: swFeatureAdvanced?.isCard,
            isCorporateUi: swFeatureAdvanced?.isCorporateUi,
            isFriendInviteFriend: swFeatureAdvanced?.isFriendInviteFriend,
            isMemberType: swFeatureAdvanced?.isMemberType,
            isSurvey: swFeatureAdvanced?.isSurvey,
            isSuspiciousActivity: swFeatureAdvanced?.isSuspiciousActivity,
            isUploadReceipt: swFeatureAdvanced?.isUploadReceipt,
            isEcommerceClaimPoints: swCollectPoint?.isEcommerceClaimPoints,
            isUploadReceiptPoint: swCollectPoint?.isUploadReceiptPoint,
            isMemberShipTier: swFeatureAdvanced?.isMemberShipTier,
            isMarketing: swFeatureAdvanced?.isMarketing,
            isService: swFeatureAdvanced?.isService,
            isPos: swFeatureAdvanced?.isPos,
            isRewardCategory: swFeatureAdvanced?.isRewardCategory
        });
        if (response.data) {
            dispatch(
                openToast({
                    message: "Success",
                    type: "success",
                    autoHideDuration: 2000,
                })
            );
            dispatch(setLoading(false));
        }
    };

    const handleSaveUploadReceipt = async () => {
        dispatch(setLoading(true));
        const response = await merchantAPI.saveConfigUploadReciept(data?._id, {
            isUse: formFormUploadReceipt?.isUse,
            isSKU: formFormUploadReceipt?.isSKU,
            isSalePoint: formFormUploadReceipt?.isSalePoint,
            isSKUPoint: formFormUploadReceipt?.isSKUPoint,
            isChannelStore: formFormUploadReceipt?.isChannelStore,
            isRequire: formFormUploadReceipt?.isRequire,
            isNetSale: formFormUploadReceipt?.isNetSale
        });
        if (response.data) {
            dispatch(
                openToast({
                    message: "Success",
                    type: "success",
                    autoHideDuration: 2000,
                })
            );
            dispatch(setLoading(false));
        } else {
            dispatch(
                openToast({
                    message: response.data.message,
                    type: "error",
                    autoHideDuration: 2000,
                })
            );
            dispatch(setLoading(false));
        }
    };

    const handleSaveCrossMerchant = async () => {
        dispatch(setLoading(true));
        const response = await merchantAPI.saveCrossMerchant(data?._id, {
            isCrossMerchantReceiptValidation:
                formCrossMerchantReceipt?.isCrossMerchantReceiptValidation,
            anotherMerchant: formCrossMerchantReceipt?.anotherMerchant,
            crossChannel: arrayCrosssChannel,
        });
        if (response.data) {
            dispatch(
                openToast({
                    message: "Success",
                    type: "success",
                    autoHideDuration: 2000,
                })
            );
            dispatch(setLoading(false));
        } else {
            dispatch(
                openToast({
                    message: response.data.message,
                    type: "error",
                    autoHideDuration: 2000,
                })
            );
            dispatch(setLoading(false));
        }
    };

    return (
        <Container>
            {data ? (
                <div className="flex-layout">
                    <div className="form">
                        <StyledCard>
                            <div className="card-inner">
                                <h4>{t("page.merchant.form_information")}</h4>
                                <div>
                                    <CardItem
                                        title={t("page.merchant.number_of_employees")}
                                        content={data?.staffAmount || "---"}
                                    />
                                    <CardItem
                                        title={t("page.merchant.experience_with_loyalty_software")}
                                        content={data?.expLoyaltyHub || "---"}
                                    />
                                    <CardItem
                                        title={t("page.merchant.company_name")}
                                        content={data?.name || "---"}
                                    />
                                    <CardItem
                                        title={t("page.merchant.business_industry")}
                                        content={data?.businessTypeName || "---"}
                                    />
                                    <CardItem
                                        title={t("page.merchant.telephone_number")}
                                        content={data?.businessTel || "---"}
                                    />
                                </div>
                            </div>
                        </StyledCard>
                        <StyledCard>
                            <div className="card-inner">
                                <h4>{t("page.merchant.admin_contact")}</h4>
                                <div>
                                    {merchantAdmin?.map((item, index) => (
                                        <CardItem
                                            key={item.id}
                                            title={t("page.merchant.admin") + (index + 1)}
                                            content={item.fullName || "---"}
                                            email={item.email || "---"}
                                        />
                                    ))}
                                </div>
                            </div>
                        </StyledCard>
                        <StyledCard>
                            <div className="card-inner">
                                <h4>{t("page.merchant.basic_info")}</h4>
                                <div>
                                    <CardItem
                                        title={t("page.merchant.shop_name")}
                                        content={data?.basicInfo?.shopName || "---"}
                                    />
                                    <CardItem
                                        title={t("page.merchant.plan")}
                                        content={data?.basicInfo?.planName || "---"}
                                    />
                                    <CardItem
                                        title={"# " + t("page.merchant.contacts")}
                                        content={String(data?.basicInfo?.contacts) || "---"}
                                    />
                                    <CardItem
                                        title={"# " + t("page.merchant.members") || "---"}
                                        content={String(data?.basicInfo?.members) || "---"}
                                    />
                                    <CardItem
                                        title={t("page.merchant.last_active_date")}
                                        content={
                                            (data?.createdAt &&
                                                t("format.date", {
                                                    date: new Date(data?.basicInfo?.lastActiveDate),
                                                })) ||
                                            "---"
                                        }
                                    />
                                    <CardItem
                                        title={"# " + t("page.merchant.member_link") || "---"}
                                        content={clientLink || "---"}
                                    />
                                </div>
                            </div>
                        </StyledCard>
                        {data?.businessInfo && (
                            <StyledCard>
                                <div className="card-inner">
                                    <h4>{t("page.merchant.basic_info")}</h4>
                                    <div>
                                        <CardItem
                                            title={t("page.merchant.total_sale")}
                                            content={data?.businessInfo?.totalSales || "---"}
                                        />
                                        <CardItem
                                            title={"# " + t("page.merchant.total_point_assign")}
                                            content={
                                                data?.businessInfo?.totalPointsAssigned || "---"
                                            }
                                        />
                                        <CardItem
                                            title={"# " + t("page.merchant.reward_active")}
                                            content={data?.businessInfo?.activeRewards || "---"}
                                        />
                                        <CardItem
                                            title={"# " + t("page.merchant.coupon_active")}
                                            content={data?.businessInfo?.activeCoupons || "---"}
                                        />
                                        <CardItem
                                            title={"# " + t("page.merchant.benefit_active")}
                                            content={data?.businessInfo?.activeBenefits || "---"}
                                        />
                                        <CardItem
                                            title={"# " + t("page.merchant.reward_redeem")}
                                            content={data?.businessInfo?.rewardsRedeemed || "---"}
                                        />
                                        <CardItem
                                            title={"# " + t("page.merchant.coupon_redeem")}
                                            content={data.businessInfo?.couponsRedeemed || "---"}
                                        />
                                        <CardItem
                                            title={"# " + t("page.merchant.benefit_redeem")}
                                            content={data?.businessInfo?.benefitsRedeemed || "---"}
                                        />
                                    </div>
                                </div>
                            </StyledCard>
                        )}
                        <StyledCard>
                            <div className="card-inner">
                                <h4>{t("page.merchant.integrations")}</h4>
                                {data?.linkedAccount && (
                                    <div>
                                        {data.linkedAccount.map((item, i) => (
                                            <CardItem
                                                key={i}
                                                title={item.name}
                                                content={item.lineNumber || "---"}
                                            />
                                        ))}
                                    </div>
                                )}
                            </div>
                        </StyledCard>
                        <StyledCard>
                            <div className="card-inner">
                                <h4>{t("page.merchant.loyalty_advanced_features")}</h4>
                                <div className="card-feature">
                                    <StyledSwitch>
                                        <Switch
                                            defaultChecked={swFeatureAdvanced?.isCorporateUi}
                                            checked={swFeatureAdvanced?.isCorporateUi}
                                            onClick={(e: boolean) =>
                                                setSWFeatureAdvanced({
                                                    ...swFeatureAdvanced,
                                                    isCorporateUi: e,
                                                })
                                            }
                                        />
                                        <p>{"Corporate UI"}</p>
                                    </StyledSwitch>
                                </div>
                                <br />
                                {/* <div className="card-feature">
                                <StyledSwitch>
                                    <Switch 
                                        defaultChecked={swFeatureAdvanced?.isUploadReceipt}
                                        checked={swFeatureAdvanced?.isUploadReceipt}
                                        onClick={(e: boolean) =>
                                            setSWFeatureAdvanced({
                                                ...swFeatureAdvanced,
                                                isUploadReceipt: e,
                                            })}
                                    />
                                    <p>{"Upload receipt"}</p>
                                </StyledSwitch>
                             </div>   
                             <br/> */}
                                <div className="card-feature">
                                    <StyledSwitch>
                                        <Switch
                                            defaultChecked={swFeatureAdvanced?.isCard}
                                            checked={swFeatureAdvanced?.isCard}
                                            onClick={(e: boolean) =>
                                                setSWFeatureAdvanced({
                                                    ...swFeatureAdvanced,
                                                    isCard: e,
                                                })
                                            }
                                        />
                                        <p>{"Card"}</p>
                                    </StyledSwitch>
                                </div>
                                <br />
                                <div className="card-feature">
                                    <StyledSwitch>
                                        <Switch
                                            defaultChecked={swFeatureAdvanced?.isSuspiciousActivity}
                                            checked={swFeatureAdvanced?.isSuspiciousActivity}
                                            onClick={(e: boolean) =>
                                                setSWFeatureAdvanced({
                                                    ...swFeatureAdvanced,
                                                    isSuspiciousActivity: e,
                                                })
                                            }
                                        />
                                        <p>{"Suspicious activity"}</p>
                                    </StyledSwitch>
                                </div>
                                <br />
                                <div className="card-feature">
                                    <StyledSwitch>
                                        <Switch
                                            defaultChecked={swFeatureAdvanced?.isFriendInviteFriend}
                                            checked={swFeatureAdvanced?.isFriendInviteFriend}
                                            onClick={(e: boolean) =>
                                                setSWFeatureAdvanced({
                                                    ...swFeatureAdvanced,
                                                    isFriendInviteFriend: e,
                                                })
                                            }
                                        />
                                        <p>{"Friend invite friend"}</p>
                                    </StyledSwitch>
                                </div>
                                <br />
                                <div className="card-feature">
                                    <StyledSwitch>
                                        <Switch
                                            defaultChecked={swFeatureAdvanced?.isSurvey}
                                            checked={swFeatureAdvanced?.isSurvey}
                                            onClick={(e: boolean) =>
                                                setSWFeatureAdvanced({
                                                    ...swFeatureAdvanced,
                                                    isSurvey: e,
                                                })
                                            }
                                        />
                                        <p>{"Survey"}</p>
                                    </StyledSwitch>
                                </div>
                                <br />
                                <div className="card-feature">
                                    <StyledSwitch>
                                        <Switch
                                            defaultChecked={swFeatureAdvanced?.isMemberType}
                                            checked={swFeatureAdvanced?.isMemberType}
                                            onClick={(e: boolean) =>
                                                setSWFeatureAdvanced({
                                                    ...swFeatureAdvanced,
                                                    isMemberType: e,
                                                })
                                            }
                                        />
                                        <p>{"Member Type"}</p>
                                    </StyledSwitch>
                                </div>
                                <br />
                                <div className="card-feature">
                                    <StyledSwitch>
                                        <Switch
                                            defaultChecked={swFeatureAdvanced?.isRewardCategory}
                                            checked={swFeatureAdvanced?.isRewardCategory}
                                            onClick={(e: boolean) =>
                                                setSWFeatureAdvanced({
                                                    ...swFeatureAdvanced,
                                                    isRewardCategory: e,
                                                })
                                            }
                                        />
                                        <p>{"Rewards Category"}</p>
                                    </StyledSwitch>
                                </div>
                                {/* <div className="card-feature">
                                <StyledSwitch>
                                    <Switch 
                                        defaultChecked={swFeatureAdvanced?.isMemberShipTier}
                                        checked={swFeatureAdvanced?.isMemberShipTier}
                                        onClick={(e: boolean) =>
                                            setSWFeatureAdvanced({
                                                ...swFeatureAdvanced,
                                                isMemberShipTier: e,
                                            })}
                                    />
                                    <p>{"Membership Tier"}</p>
                                </StyledSwitch>
                             </div>    */}

                                <Divider />

                                <h4>{t("page.merchant.collect_point_method")}</h4>
                                <div className="card-feature">
                                    <StyledSwitch>
                                        <Switch
                                            defaultChecked={swCollectPoint?.isUploadReceiptPoint}
                                            checked={swCollectPoint?.isUploadReceiptPoint}
                                            onClick={(e: boolean) =>
                                                setSWCollectPoint({
                                                    ...swCollectPoint,
                                                    isUploadReceiptPoint: e,
                                                })
                                            }
                                        />
                                        <p>{"Upload receipt"}</p>
                                    </StyledSwitch>
                                </div>
                                <br />
                                <div className="card-feature">
                                    <StyledSwitch>
                                        <Switch
                                            defaultChecked={swCollectPoint?.isEcommerceClaimPoints}
                                            checked={swCollectPoint?.isEcommerceClaimPoints}
                                            onClick={(e: boolean) =>
                                                setSWCollectPoint({
                                                    ...swCollectPoint,
                                                    isEcommerceClaimPoints: e,
                                                })
                                            }
                                        />
                                        <p>{"Ecommerce claim points"}</p>
                                    </StyledSwitch>
                                </div>
                            </div>
                            <Divider />

                            <h4>{"Modules"}</h4>
                            <div className="card-feature">
                                <StyledSwitch>
                                    <Switch
                                        defaultChecked={swFeatureAdvanced?.isMarketing}
                                        checked={swFeatureAdvanced?.isMarketing}
                                        onClick={(e: boolean) =>
                                            setSWFeatureAdvanced({
                                                ...swFeatureAdvanced,
                                                isMarketing: e,
                                            })
                                        }
                                    />
                                    <p>{"Marketing Automation"}</p>
                                </StyledSwitch>
                            </div>
                            <br />
                            <div className="card-feature">
                                <StyledSwitch>
                                    <Switch
                                        defaultChecked={swFeatureAdvanced?.isService}
                                        checked={swFeatureAdvanced?.isService}
                                        onClick={(e: boolean) =>
                                            setSWFeatureAdvanced({
                                                ...swFeatureAdvanced,
                                                isService: e,
                                            })
                                        }
                                    />
                                    <p>{"Service"}</p>
                                </StyledSwitch>
                            </div>
                            <br />
                            <div className="card-feature">
                                <StyledSwitch>
                                    <Switch
                                        defaultChecked={swFeatureAdvanced?.isPos}
                                        checked={swFeatureAdvanced?.isPos}
                                        onClick={(e: boolean) =>
                                            setSWFeatureAdvanced({
                                                ...swFeatureAdvanced,
                                                isPos: e,
                                            })
                                        }
                                    />
                                    <p>{"POS"}</p>
                                </StyledSwitch>
                            </div>
                            <br />
                            <Divider />

                            <Button
                                className="primary-btn"
                                type={"primary"}
                                onClick={handleSaveAdvancedFeatureAndCollectPoint}
                            >
                                {t("page.save")}
                            </Button>
                        </StyledCard>
                    </div>

                    <div className="note-layout">
                        <StyledCard>
                            <div className="card-inner">
                                <h4>{t("page.merchant.merchant_global_setting")}</h4>
                                <div className="title-name">
                                    <b>{"Tier By"}</b>
                                </div>
                                <div>
                                    <Radio.Group
                                        onChange={(e) => setTierBy(e.target.value)}
                                        value={tierBy}
                                    >
                                        <Radio value={1}>{"Point"}</Radio>
                                        <Radio value={2}>{"Sale"}</Radio>
                                    </Radio.Group>
                                </div>
                                <br />
                                <div className="title-name">
                                    <b>{"Tier rules"}</b>
                                </div>
                                <div>
                                    <Radio.Group
                                        onChange={(e) => setGlobalSetting(e.target.value)}
                                        value={globalSetting}
                                    >
                                        {tierBy === 1 && <Radio value={1}>{"Point balance"}</Radio>}
                                        <Radio value={2}>
                                            {tierBy === 1 ? "Points earned" : "Amount earned"}
                                        </Radio>
                                    </Radio.Group>
                                </div>
                                <div className="title-name-global-setting">
                                    <b>{"Tiering"}</b>
                                </div>
                                <div>
                                    <StyledSwitchGlobalSetting>
                                        <Switch
                                            defaultChecked={formGlobalSetting?.isTier}
                                            checked={formGlobalSetting?.isTier}
                                            onClick={(e: boolean) => {
                                                setFormGlobalSetting({
                                                    ...formGlobalSetting,
                                                    isTier: e,
                                                });
                                                setSWFeatureAdvanced({
                                                    ...swFeatureAdvanced,
                                                    isMemberShipTier: e,
                                                });
                                            }}
                                        />
                                        <p>{"Enable tier"}</p>
                                    </StyledSwitchGlobalSetting>
                                </div>

                                {formGlobalSetting?.isTier && (
                                    <>
                                        <StyledSelectGlobalSetting>
                                            <Row gutter={24} style={{ paddingTop: 15 }}>
                                                <Col span={12}>
                                                    <Select
                                                        value={formGlobalSetting?.membershipTiersId}
                                                        onChange={(e) =>
                                                            setFormGlobalSetting({
                                                                ...formGlobalSetting,
                                                                membershipTiersId: e,
                                                            })
                                                        }
                                                        style={{ width: "100%" }}
                                                    >
                                                        {selectTier?.map((i) => (
                                                            <Option value={i?._id}>
                                                                {i?.membershipName}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Col>
                                                <Col span={12}>
                                                    <Button
                                                        className="primary-btn"
                                                        type={"primary"}
                                                        //  onClick={handleSaveMerchantGlobalSetting}
                                                    >
                                                        {"Sync Tier Update"}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </StyledSelectGlobalSetting>
                                    </>
                                )}

                                <div className="title-name-global-setting">
                                    <b>{"Seller member type"}</b>
                                </div>
                                <div>
                                    <StyledSwitchGlobalSetting>
                                        <Switch
                                            defaultChecked={formGlobalSetting?.isSellerTier}
                                            checked={formGlobalSetting?.isSellerTier}
                                            onClick={(e: boolean) =>
                                                setFormGlobalSetting({
                                                    ...formGlobalSetting,
                                                    isSellerTier: e,
                                                })
                                            }
                                        />
                                        <p>{"Enable"}</p>
                                    </StyledSwitchGlobalSetting>
                                </div>
                                <Row gutter={24} style={{ paddingTop: 15 }}>
                                    <Col span={12}>
                                        <div className="div-form-item">
                                            <p style={{ paddingBottom: 5 }}>
                                                {"Upload evidence text display"}
                                            </p>
                                            <Input
                                                onChange={(e: any) =>
                                                    setFormGlobalSetting({
                                                        ...formGlobalSetting,
                                                        uploadEvidenceDisplay: e.target.value,
                                                    })
                                                }
                                                value={formGlobalSetting?.uploadEvidenceDisplay}
                                            />
                                        </div>
                                    </Col>
                                    <Col span={12}>
                                        <div className="div-form-item">
                                            <p style={{ paddingBottom: 5 }}>
                                                {"Upload unit display"}
                                            </p>
                                            <Input
                                                onChange={(e: any) =>
                                                    setFormGlobalSetting({
                                                        ...formGlobalSetting,
                                                        uploadUnitDisplay: e.target.value,
                                                    })
                                                }
                                                value={formGlobalSetting?.uploadUnitDisplay}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <br />
                                <div className="title-name">
                                    <b>{"Name field"}</b>
                                </div>
                                <div>
                                    <Radio.Group
                                        defaultValue={data?.superAdminTier?.isFullName}
                                        value={formGlobalSetting?.isFullName}
                                        onChange={(e) => {
                                            setFormGlobalSetting({
                                                ...formGlobalSetting,
                                                isFullName: e.target.value,
                                            });
                                        }}
                                    >
                                        <Radio value={true}>{"1 full name field"}</Radio>
                                        <Radio value={false}>
                                            {"separate first / last name fields"}
                                        </Radio>
                                    </Radio.Group>
                                </div>
                                <br />
                                <div className="title-name">
                                    <b>{"Component style"}</b>
                                </div>
                                <div>
                                    <Radio.Group
                                        defaultValue={data?.superAdminTier?.isComponentRound}
                                        value={formGlobalSetting?.isRound}
                                        onChange={(e) => {
                                            setFormGlobalSetting({
                                                ...formGlobalSetting,
                                                isRound: e.target.value,
                                            });
                                        }}
                                    >
                                        <Radio value={true}>{"Round"}</Radio>
                                        <Radio value={false}>{"sharp"}</Radio>
                                    </Radio.Group>
                                </div>
                                <br />
                                <div className="title-name">
                                    <b>{"Display member type badge"}</b>
                                </div>
                                <div>
                                    <Radio.Group
                                        defaultValue={data?.superAdminTier?.isTierDisplay}
                                        value={formGlobalSetting?.isDisplayTier}
                                        onChange={(e) => {
                                            setFormGlobalSetting({
                                                ...formGlobalSetting,
                                                isDisplayTier: e.target.value,
                                            });
                                        }}
                                    >
                                        <Radio value={true}>{"show"}</Radio>
                                        <Radio value={false}>{"hide"}</Radio>
                                    </Radio.Group>
                                </div>
                                <Button
                                    className="primary-btn"
                                    type={"primary"}
                                    onClick={handleSaveMerchantGlobalSetting}
                                >
                                    {t("page.save")}
                                </Button>
                            </div>
                        </StyledCard>
                        <StyledCard>
                            <div className="card-inner">
                                <h4>
                                    {t("page.merchant.upload") +
                                        " " +
                                        formGlobalSetting.uploadEvidenceDisplay}
                                </h4>
                                <div>
                                    <StyledSwitchGlobalSetting>
                                        <Switch
                                            defaultChecked={formFormUploadReceipt?.isUse}
                                            checked={formFormUploadReceipt?.isUse}
                                            onClick={(e) =>
                                                setFormUploadReceipt({
                                                    ...formFormUploadReceipt,
                                                    isUse: e,
                                                })
                                            }
                                        />
                                        <p>{"Enable feature"}</p>
                                    </StyledSwitchGlobalSetting>
                                </div>
                                <Divider />
                                {formFormUploadReceipt?.isUse && (
                                    <>
                                        <p
                                            style={{
                                                color: "black",
                                                fontWeight: "bold",
                                                paddingBottom: 3,
                                            }}
                                        >
                                            {"Enable assign channel / store"}
                                        </p>
                                        <div>
                                            <StyledSwitchGlobalSetting>
                                                <Switch
                                                    defaultChecked={
                                                        formFormUploadReceipt?.isChannelStore
                                                    }
                                                    checked={formFormUploadReceipt?.isChannelStore}
                                                    onClick={(e) =>
                                                        setFormUploadReceipt({
                                                            ...formFormUploadReceipt,
                                                            isChannelStore: e,
                                                        })
                                                    }
                                                />
                                                <p>
                                                    {
                                                        "Enabling will allow admin to assign channel / store"
                                                    }
                                                </p>
                                            </StyledSwitchGlobalSetting>
                                        </div>
                                        {formFormUploadReceipt?.isChannelStore && (
                                            <>
                                                <div>
                                                    <StyledSwitchGlobalSetting>
                                                        <Switch
                                                            defaultChecked={
                                                                formFormUploadReceipt?.isRequire
                                                            }
                                                            checked={
                                                                formFormUploadReceipt?.isRequire
                                                            }
                                                            onClick={(e) =>
                                                                setFormUploadReceipt({
                                                                    ...formFormUploadReceipt,
                                                                    isRequire: e,
                                                                })
                                                            }
                                                        />
                                                        <p>{"Required"}</p>
                                                    </StyledSwitchGlobalSetting>
                                                </div>
                                            </>
                                        )}
                                        <br />
                                        <p
                                            style={{
                                                color: "black",
                                                fontWeight: "bold",
                                                paddingBottom: 5,
                                            }}
                                        >
                                            {"Enable assign SKU"}
                                        </p>
                                        <div>
                                            <StyledSwitchGlobalSetting>
                                                <Switch
                                                    defaultChecked={formFormUploadReceipt?.isSKU}
                                                    checked={formFormUploadReceipt?.isSKU}
                                                    onClick={(e) =>
                                                        setFormUploadReceipt({
                                                            ...formFormUploadReceipt,
                                                            isSKU: e,
                                                        })
                                                    }
                                                />
                                                <p>
                                                    {
                                                        "Enabling will allow admin to assign SKU to order"
                                                    }
                                                </p>
                                            </StyledSwitchGlobalSetting>
                                        </div>
                                        <br />
                                        <p
                                            style={{
                                                color: "black",
                                                fontWeight: "bold",
                                                paddingBottom: 5,
                                            }}
                                        >
                                            {"Enable SKU sale amount field"}
                                        </p>
                                        <div>
                                            <StyledSwitchGlobalSetting>
                                                <Switch
                                                    defaultChecked={
                                                        formFormUploadReceipt?.isNetSale
                                                    }
                                                    checked={formFormUploadReceipt?.isNetSale}
                                                    onClick={(e) =>
                                                        setFormUploadReceipt({
                                                            ...formFormUploadReceipt,
                                                            isNetSale: e,
                                                        })
                                                    }
                                                />
                                                <p>
                                                    {
                                                        "Enabling will add sales amount per each SKU field on approve receipt"
                                                    }
                                                </p>
                                            </StyledSwitchGlobalSetting>
                                        </div>
                                        <br />
                                        {formFormUploadReceipt?.isSKU && (
                                            <>
                                                <p
                                                    style={{
                                                        color: "black",
                                                        fontWeight: "bold",
                                                        paddingBottom: 5,
                                                    }}
                                                >
                                                    {"Give Points By"}
                                                </p>
                                                <div>
                                                    <Radio.Group
                                                        defaultValue={
                                                            (formFormUploadReceipt?.isSalePoint ===
                                                                true &&
                                                                1) ||
                                                            (formFormUploadReceipt?.isSKUPoint ===
                                                                true &&
                                                                2)
                                                        }
                                                        onChange={(e) => {
                                                            setFormUploadReceipt({
                                                                ...formFormUploadReceipt,
                                                                isSalePoint:
                                                                    e.target.value === 1
                                                                        ? true
                                                                        : false,
                                                                isSKUPoint:
                                                                    e.target.value === 2
                                                                        ? true
                                                                        : false,
                                                            });
                                                        }}
                                                        // value={globalSetting}
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            rowGap: 12,
                                                        }}
                                                    >
                                                        <Radio value={1}>{"Sales amount"}</Radio>
                                                        <Radio value={2}>{"SKU"}</Radio>
                                                    </Radio.Group>
                                                </div>
                                            </>
                                        )}
                                        <br />
                                    </>
                                )}
                                <Button
                                    className="primary-btn"
                                    type={"primary"}
                                    onClick={handleSaveUploadReceipt}
                                >
                                    {t("page.save")}
                                </Button>
                            </div>
                        </StyledCard>
                        <StyledCard>
                            <div className="card-inner">
                                <h4>{t("page.merchant.sale_notes")}</h4>
                                <div>
                                    <TextArea
                                        autosize={{ minRows: 8, maxRows: 10 }}
                                        onChange={handleChange}
                                        value={note}
                                    />
                                </div>
                            </div>

                            <Button className="primary-btn" type={"primary"} onClick={handleSent}>
                                {t("page.save")}
                            </Button>
                        </StyledCard>

                        <StyledCard>
                            <div className="card-inner">
                                <h4>{t("page.merchant.booking_field")}</h4>
                                <div>
                                    {BookingFieldList?.map((fItem, fIndex) => {
                                        return (
                                            <div className="field-item">
                                                <div className="field-name">
                                                    {fItem?.booking_topic_note_name}
                                                </div>
                                                <p onClick={() => deleteField(fItem)}>
                                                    <IconDelete color="black" />
                                                </p>
                                            </div>
                                        );
                                    })}

                                    <Button onClick={() => setShowBookingField(true)}>
                                        + Add Booking field
                                    </Button>
                                </div>
                            </div>

                            <Button className="primary-btn" type={"primary"} onClick={saveBooking}>
                                {t("page.save")}
                            </Button>
                        </StyledCard>

                        <StyledCard>
                            <div className="card-inner">
                                <h4>{"Cross merchant receipt validation"}</h4>
                                <StyledSwitchGlobalSetting>
                                    <Switch
                                        defaultChecked={
                                            formCrossMerchantReceipt?.isCrossMerchantReceiptValidation
                                        }
                                        checked={
                                            formCrossMerchantReceipt?.isCrossMerchantReceiptValidation
                                        }
                                        onClick={(e) =>
                                            setFormCrossMerchantReceipt({
                                                ...formCrossMerchantReceipt,
                                                isCrossMerchantReceiptValidation: e,
                                            })
                                        }
                                    />
                                    <p>{`Turn ${
                                        formCrossMerchantReceipt?.isCrossMerchantReceiptValidation
                                            ? "On"
                                            : "Off"
                                    }`}</p>
                                </StyledSwitchGlobalSetting>
                                {formCrossMerchantReceipt?.isCrossMerchantReceiptValidation && (
                                    <>
                                      <Divider />
                                        <Row gutter={24} style={{ paddingTop: 5 }}>
                                            <Col span={24}>
                                                <div className="div-form-item">
                                                    <p>{"Merchant ID"}</p>
                                                    <Input
                                                    onChange={(e: any) =>
                                                        setFormCrossMerchantReceipt({
                                                            ...formCrossMerchantReceipt,
                                                            anotherMerchant: e.target.value,
                                                        })
                                                    }
                                                    value={formCrossMerchantReceipt?.anotherMerchant}
                                                    allowClear
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <br />
                                        {arrayCrosssChannel?.map((item: any, index: number) => (
                                        <StyledListFields>
                                            <div className="icon-drag">
                                                <IconDrag color="#FFFFFF" />
                                            </div>
                                            <div className="input-data">
                                                <Row style={{ width: "100%" }}>
                                                    <Col span={10}>
                                                        <StyledSelect>
                                                            <p className="label">
                                                                {"Own merchant channel"}
                                                            </p>
                                                            <Select
                                                                onChange={(e) =>
                                                                    changeFieldListForm(
                                                                        e,
                                                                        index,
                                                                        "channelId"
                                                                    )
                                                                }
                                                                value={item?.channelId}
                                                                style={{ width: "100%" }}
                                                            >
                                                               {dataChanel?.map((items: any) => (
                                                                <Select.Option
                                                                    value={items?.channelList?._id}
                                                                >
                                                                    {items?.channelList?.channelName }
                                                                </Select.Option>
                                                            ))}
                                                            </Select>
                                                        </StyledSelect>
                                                    </Col>

                                                    <Col span={4}>
                                                        <IconArrowRight />
                                                    </Col>
                                                    <Col span={10}>
                                                        {/* <StyledContainer>
                                                            <div className="form-input">
                                                                <p style={{ color: "#000" }}>
                                                                    {t("Cross merchant channel id")}
                                                                </p>
                                                                <Input
                                                                    className="input-add-contact"
                                                                    onChange={(e) =>
                                                                        changeFieldListForm(
                                                                            e.target.value,
                                                                            index,
                                                                            "crossChannelId"
                                                                        )
                                                                    }
                                                                    value={item?.crossChannelId}
                                                                />
                                                            </div>
                                                        </StyledContainer> */}
                                                        <StyledSelect>
                                                            <p className="label">
                                                                {"Cross merchant channel id"}
                                                            </p>
                                                            <Select
                                                                onChange={(e) =>
                                                                    changeFieldListForm(
                                                                        e,
                                                                        index,
                                                                        "crossChannelId"
                                                                    )
                                                                }
                                                                value={item?.crossChannelId}
                                                                style={{ width: "100%" }}
                                                            >
                                                               {dataChanelAnotherMerchant?.map((items: any) => (
                                                                <Select.Option
                                                                    value={items?.channelList?._id}
                                                                >
                                                                    {items?.channelList?.channelName }
                                                                </Select.Option>
                                                            ))}
                                                            </Select>
                                                        </StyledSelect>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <span
                                            onClick={() => handleDeleteFields(index)}
                                            >
                                                <IClose />
                                            </span>
                                        </StyledListFields>
                                     ))} 
                                        <Button
                                        onClick={addFieldsList}
                                        >
                                            + Add Channel
                                        </Button>
                                    </>
                                )}
                                  <br />
                                        <Button
                                            className="primary-btn"
                                            type={"primary"}
                                            onClick={handleSaveCrossMerchant}
                                        >
                                            {t("page.save")}
                                        </Button>
                            </div>
                        </StyledCard>
                    </div>
                </div>
            ) : (
                <div>nodata</div>
            )}
            <Drawer
                // closable={false}
                width={400}
                placement="right"
                onClose={() => setShowBookingField(false)}
                visible={showBookingField}
                className="sidebar-drawer"
                title="create booking field"
            >
                <StyledContainer>
                    <div className="form-input" style={{ marginBottom: 20 }}>
                        <p>{t("page.merchant.display_name")}</p>
                        <Input
                            className="input-add-contact"
                            name="displayName"
                            type="text"
                            value={newField}
                            onChange={(e) => setNewField(e.target.value)}
                            onPressEnter={saveField}
                        />
                    </div>
                    <div className="button-field">
                        <Button className="field-btn" onClick={saveField}>
                            Ok
                        </Button>
                        <Button className="field-btn cancel" onClick={cancelField}>
                            Cancel
                        </Button>
                    </div>
                </StyledContainer>
            </Drawer>
        </Container>
    );
};
const Container = styled.div`
    display: flex;
    flex-direction: column;
    .flex-layout {
        display: flex;
        justify-content: flex-start;
        .note-layout {
            flex: 1;
            max-width: 647px;
        }
    }
    .form {
        flex: 1;
        height: 100%;
        overflow-y: auto;
        max-width: 647px;
        width: 100%;
        padding-right: 10px;
        @media screen and (max-width: ${(p) => p.theme.breakPoints.breakMobileMedium}) {
            padding-right: 0;
            ::-webkit-scrollbar {
                display: none;
            }
        }
        ::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }

        ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px grey;
            border-radius: 10px;
        }

        ::-webkit-scrollbar-thumb {
            background: ${(p) => p.theme.colors.fadedText};
            border-radius: 10px;
        }

        ::-webkit-scrollbar-thumb:hover {
            cursor: grab;
        }
    }
`;
const ContentInfo = styled.div`
    margin: 16px 0;
    .cate-info {
        font-weight: 600;
        /* margin-bottom: 0; */
        font-size: 14px;
        color: black;
    }
    .content-info {
        color: #646464;
        font-weight: 600;
        font-size: 16px;
    }
`;

const StyledSwitch = styled.div`
    display: flex;
    align-items: center;
    p {
        margin-bottom: 0;
        padding-left: 15px;
        font-weight: 600;
        color: black;
        font-size: 16px;
    }
`;


const StyledSwitchGlobalSetting = styled.div`
    display: flex;
    align-items: center;
    p {
        margin-bottom: 0;
        padding-left: 15px;
        font-size: 14px;
    }
`;
const StyledSelectGlobalSetting = styled.div`
.ant-select-selection {
    padding-top: 8px;
    padding-left: 6px;
}
`;

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    .add-option-item {
        margin-top: 12px;
    }
    .select-type-field {
        margin-top: 32px;

        button {
            margin-right: 6px;
            min-width: 80px;
            color: ${(p) => p.theme.colors.fadedText} !important;
        }
        .selected {
            background-color: ${(p) => p.theme.colors.fadedText};
            color: white !important;
        }
    }
    .center-icon {
        margin: 0 auto 16px auto;
        width: fit-content;
    }
    .input-option {
        height: ${(p) => p.theme.heights.input};
        font-size: 16px;
    }
    .form-input {
        margin-bottom: 0;
        font-weight: 600;
        .example-text {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #6c7084;
        }
    }
    .form-option {
        background: #f7f7f8;
        border-radius: 8px;
        padding: 20px;
        .ant-radio-group {
            display: flex;
            justify-content: space-between;
            align-items: center;
            column-gap: 10px;

            .ant-radio-wrapper {
                width: 100%;
                padding: 20px;
                background: #ffffff;
                border: 0.5px solid #a5a5a5;
                border-radius: 6px;
            }
            .active {
                border: 1.5px solid #0263e0;
            }
        }
    }
    .input-invalid {
        border-color: ${(p) => p.theme.colors.danger}!important;
    }
    .text-err {
        text-align: left;
        color: #f43f3f;
        visibility: visible;
        font-size: 11px;
        font-weight: 600;
    }

    .option-show-item {
        display: flex;
        align-items: center;
        .icon {
            margin-bottom: 16px;
        }
        .input-item {
            margin-right: 8px;
            input {
                cursor: inherit;
            }
        }
        .icon {
            margin-left: 8px;
            cursor: pointer;
        }
    }
    .validate-option {
        text-align: left;
        color: #f43f3f;
        visibility: visible;
        font-size: 11px;
        font-weight: 600;
        margin-bottom: 4px;
    }
    .text-hidden {
        visibility: hidden;
    }
    .button-field {
        position: fixed;
        bottom: 0;

        .field-btn {
            margin-right: 30px;
            margin-bottom: 20px;
            width: 150px;
            height: 48px;
            font-size: 18px;
            font-weight: 500;
            color: white;
            background-color: #0262e0;
        }
        .cancel {
            color: #0262e0;
            background-color: white;
        }
    }
`;

const StyledListFields = styled.div`
    display: flex;
    align-items: center;
    align-self: stretch;
    padding-bottom: 1rem;
    .icon-drag {
        display: flex;
        padding: 0px 4px;
        align-items: center;
        align-self: stretch;
        background: var(--Merchant-Admin-Text-4, #6c7084);
        border-bottom-left-radius: 8px;
        border-top-left-radius: 8px;
    }
    .input-data {
        display: flex;
        padding: 20px;
        align-items: center;
        gap: 12px;
        flex: 1 0 0;
        background: var(--Merchant-Admin-Background, #f7f7f8);
        border-bottom-right-radius: 8px;
        border-top-right-radius: 8px;
        margin-right: 10px;
    }
`;

const StyledSelect = styled.div`
    p {
        font-style: normal;
        font-weight: 600;
        line-height: 21px;
        color: #000000;
        margin-bottom: 8px;
    }
    .ant-select-selection--single,
    .ant-select-selection__rendered {
        height: ${(p) => p.theme.heights.input};
        display: flex;
        align-items: center;
    }
`;
